var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.sumber_limbah && _vm.data_limbah && _vm.olah_limbah && _vm.olah_limbah_cair)?_c('div',[_c('div',[_c('vue-html2pdf',{ref:"html2Pdf",attrs:{"show-layout":false,"enable-download":true,"preview-modal":true,"paginate-elements-by-height":1400,"filename":"formulir-covid19","pdf-quality":2,"manual-pagination":false,"pdf-format":"a4","pdf-orientation":"portrait","pdf-content-width":"100%"}},[_c('div',{staticStyle:{"padding":"10px"},attrs:{"slot":"pdf-content"},slot:"pdf-content"},[_c('profile-detil-data',{attrs:{"profile":_vm.profile}}),_c('p',{staticClass:"small_txt color_txt"},[_vm._v(" "+_vm._s(_vm.$date(_vm.created).format("MMMM YYYY"))+" ")]),_c('div',{staticClass:"mb-5 bg_header",attrs:{"flat":""}},[_c('div',{staticClass:"fill_header"},[_c('b',[_vm._v("1. Sumber Limbah Padat Covid-19")])])]),_c('v-card',{staticClass:"bordered_card rounded-max",attrs:{"outlined":""}},[_c('div',{staticClass:"mb-3"},[_c('b',[_vm._v("Jumlah Pasien Covid-19")])]),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Rata-rata jumlah pasien covid-19 di Fasyankes ")])])]),_c('v-col',{attrs:{"cols":"7"}},[_c('v-card',{staticClass:"d-flex align-center pl-2 px-5",staticStyle:{"width":"max-content"},attrs:{"outlined":""}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                      _vm.sumber_limbah.jumlah_pasien_covid_19
                        .rata_rata_jumlah_pasien_covid_19_di_fasyankes
                        .dataPenyusun_desc
                    ),expression:"\n                      sumber_limbah.jumlah_pasien_covid_19\n                        .rata_rata_jumlah_pasien_covid_19_di_fasyankes\n                        .dataPenyusun_desc\n                    "}],staticClass:"pa-2 mr-1 custom_input",attrs:{"type":"number","disabled":""},domProps:{"value":(
                      _vm.sumber_limbah.jumlah_pasien_covid_19
                        .rata_rata_jumlah_pasien_covid_19_di_fasyankes
                        .dataPenyusun_desc
                    )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.sumber_limbah.jumlah_pasien_covid_19
                        .rata_rata_jumlah_pasien_covid_19_di_fasyankes
                        , "dataPenyusun_desc", $event.target.value)}}}),_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v("Orang/hari")])])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Rata-rata jumlah pasien covid-19 di Isolasi Terkendali (Isoter) ")])])]),_c('v-col',{attrs:{"cols":"7"}},[_c('v-card',{staticClass:"d-flex align-center pl-2 px-5",staticStyle:{"width":"max-content"},attrs:{"outlined":""}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                      _vm.sumber_limbah.jumlah_pasien_covid_19
                        .rata_rata_jumlah_pasien_covid_19_di_isolasi_terkendali__isoter_
                        .dataPenyusun_desc
                    ),expression:"\n                      sumber_limbah.jumlah_pasien_covid_19\n                        .rata_rata_jumlah_pasien_covid_19_di_isolasi_terkendali__isoter_\n                        .dataPenyusun_desc\n                    "}],staticClass:"pa-2 mr-1 custom_input",attrs:{"type":"number","disabled":""},domProps:{"value":(
                      _vm.sumber_limbah.jumlah_pasien_covid_19
                        .rata_rata_jumlah_pasien_covid_19_di_isolasi_terkendali__isoter_
                        .dataPenyusun_desc
                    )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.sumber_limbah.jumlah_pasien_covid_19
                        .rata_rata_jumlah_pasien_covid_19_di_isolasi_terkendali__isoter_
                        , "dataPenyusun_desc", $event.target.value)}}}),_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v("Orang/hari")])])],1)],1)],1),_c('div',{staticClass:"mb-5 bg_header",attrs:{"flat":""}},[_c('div',{staticClass:"fill_header"},[_c('b',[_vm._v("2. Data Limbah Padat Covid-19")])])]),_c('v-card',{staticClass:"bordered_card rounded-max",attrs:{"outlined":""}},[_c('div',{staticClass:"mb-3"},[_c('b',[_vm._v("Timbulan limbah domestik covid-19")])]),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Jumlah limbah domestik ")])])]),_c('v-col',{attrs:{"cols":"7"}},[_c('v-card',{staticClass:"d-flex align-center pl-2 px-5",staticStyle:{"width":"max-content"},attrs:{"outlined":""}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                      _vm.data_limbah.timbulan_limbah_domestik_covid_19
                        .jumlah_limbah_domestik.dataPenyusun_desc
                    ),expression:"\n                      data_limbah.timbulan_limbah_domestik_covid_19\n                        .jumlah_limbah_domestik.dataPenyusun_desc\n                    "}],staticClass:"pa-2 mr-1 custom_input",attrs:{"type":"number","disabled":""},domProps:{"value":(
                      _vm.data_limbah.timbulan_limbah_domestik_covid_19
                        .jumlah_limbah_domestik.dataPenyusun_desc
                    )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.data_limbah.timbulan_limbah_domestik_covid_19
                        .jumlah_limbah_domestik, "dataPenyusun_desc", $event.target.value)}}}),_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v("kg/hari")])])],1)],1)],1),_c('v-card',{staticClass:"bordered_card rounded-max",attrs:{"outlined":""}},[_c('div',{staticClass:"mb-3"},[_c('b',[_vm._v("Timbulan Limbah medis Infeksius covid-19")])]),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Jumlah limbah Infeksius ")])])]),_c('v-col',{attrs:{"cols":"7"}},[_c('v-card',{staticClass:"d-flex align-center pl-2 px-5",staticStyle:{"width":"max-content"},attrs:{"outlined":""}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                      _vm.data_limbah.timbulan_limbah_medis__infeksius_covid_19
                        .jumlah_limbah_infeksius.dataPenyusun_desc
                    ),expression:"\n                      data_limbah.timbulan_limbah_medis__infeksius_covid_19\n                        .jumlah_limbah_infeksius.dataPenyusun_desc\n                    "}],staticClass:"pa-2 mr-1 custom_input",attrs:{"disabled":"","type":"number"},domProps:{"value":(
                      _vm.data_limbah.timbulan_limbah_medis__infeksius_covid_19
                        .jumlah_limbah_infeksius.dataPenyusun_desc
                    )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.data_limbah.timbulan_limbah_medis__infeksius_covid_19
                        .jumlah_limbah_infeksius, "dataPenyusun_desc", $event.target.value)}}}),_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v("kg/hari")])])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v("Jumlah limbah Tajam")])])]),_c('v-col',{attrs:{"cols":"7"}},[_c('v-card',{staticClass:"d-flex align-center pl-2 px-5",staticStyle:{"width":"max-content"},attrs:{"outlined":""}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                      _vm.data_limbah.timbulan_limbah_medis__infeksius_covid_19
                        .jumlah_limbah_tajam.dataPenyusun_desc
                    ),expression:"\n                      data_limbah.timbulan_limbah_medis__infeksius_covid_19\n                        .jumlah_limbah_tajam.dataPenyusun_desc\n                    "}],staticClass:"pa-2 mr-1 custom_input",attrs:{"type":"number","disabled":""},domProps:{"value":(
                      _vm.data_limbah.timbulan_limbah_medis__infeksius_covid_19
                        .jumlah_limbah_tajam.dataPenyusun_desc
                    )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.data_limbah.timbulan_limbah_medis__infeksius_covid_19
                        .jumlah_limbah_tajam, "dataPenyusun_desc", $event.target.value)}}}),_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v("kg/hari")])])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Jumlah limbah Farmasi ")])])]),_c('v-col',{attrs:{"cols":"7"}},[_c('v-card',{staticClass:"d-flex align-center pl-2 px-5",staticStyle:{"width":"max-content"},attrs:{"outlined":""}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                      _vm.data_limbah.timbulan_limbah_medis__infeksius_covid_19
                        .jumlah_limbah_farmasi.dataPenyusun_desc
                    ),expression:"\n                      data_limbah.timbulan_limbah_medis__infeksius_covid_19\n                        .jumlah_limbah_farmasi.dataPenyusun_desc\n                    "}],staticClass:"pa-2 mr-1 custom_input",attrs:{"type":"number","disabled":""},domProps:{"value":(
                      _vm.data_limbah.timbulan_limbah_medis__infeksius_covid_19
                        .jumlah_limbah_farmasi.dataPenyusun_desc
                    )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.data_limbah.timbulan_limbah_medis__infeksius_covid_19
                        .jumlah_limbah_farmasi, "dataPenyusun_desc", $event.target.value)}}}),_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v("kg/hari")])])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Jumlah limbah Patologi ")])])]),_c('v-col',{attrs:{"cols":"7"}},[_c('v-card',{staticClass:"d-flex align-center pl-2 px-5",staticStyle:{"width":"max-content"},attrs:{"outlined":""}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                      _vm.data_limbah.timbulan_limbah_medis__infeksius_covid_19
                        .jumlah_limbah_patologi.dataPenyusun_desc
                    ),expression:"\n                      data_limbah.timbulan_limbah_medis__infeksius_covid_19\n                        .jumlah_limbah_patologi.dataPenyusun_desc\n                    "}],staticClass:"pa-2 mr-1 custom_input",attrs:{"type":"number","disabled":""},domProps:{"value":(
                      _vm.data_limbah.timbulan_limbah_medis__infeksius_covid_19
                        .jumlah_limbah_patologi.dataPenyusun_desc
                    )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.data_limbah.timbulan_limbah_medis__infeksius_covid_19
                        .jumlah_limbah_patologi, "dataPenyusun_desc", $event.target.value)}}}),_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v("kg/hari")])])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v("Jumlah limbah Kimia")])])]),_c('v-col',{attrs:{"cols":"7"}},[_c('v-card',{staticClass:"d-flex align-center pl-2 px-5",staticStyle:{"width":"max-content"},attrs:{"outlined":""}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                      _vm.data_limbah.timbulan_limbah_medis__infeksius_covid_19
                        .jumlah_limbah_kimia.dataPenyusun_desc
                    ),expression:"\n                      data_limbah.timbulan_limbah_medis__infeksius_covid_19\n                        .jumlah_limbah_kimia.dataPenyusun_desc\n                    "}],staticClass:"pa-2 mr-1 custom_input",attrs:{"type":"number","disabled":""},domProps:{"value":(
                      _vm.data_limbah.timbulan_limbah_medis__infeksius_covid_19
                        .jumlah_limbah_kimia.dataPenyusun_desc
                    )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.data_limbah.timbulan_limbah_medis__infeksius_covid_19
                        .jumlah_limbah_kimia, "dataPenyusun_desc", $event.target.value)}}}),_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v("kg/hari")])])],1)],1)],1),_c('v-card',{staticClass:"bordered_card rounded-max",attrs:{"outlined":""}},[_c('div',{staticClass:"mb-3"},[_c('b',[_vm._v("Timbulan Limbah Isolasi Terpusat (Isoter)")])]),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Jumlah limbah infeksius ")])])]),_c('v-col',{attrs:{"cols":"7"}},[_c('v-card',{staticClass:"d-flex align-center pl-2 px-5",staticStyle:{"width":"max-content"},attrs:{"outlined":""}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                      _vm.data_limbah.timbulan_limbah_isolasi_terkendali__isoter_
                        .jumlah_limbah_infeksius.dataPenyusun_desc
                    ),expression:"\n                      data_limbah.timbulan_limbah_isolasi_terkendali__isoter_\n                        .jumlah_limbah_infeksius.dataPenyusun_desc\n                    "}],staticClass:"pa-2 mr-1 custom_input",attrs:{"type":"number","disabled":""},domProps:{"value":(
                      _vm.data_limbah.timbulan_limbah_isolasi_terkendali__isoter_
                        .jumlah_limbah_infeksius.dataPenyusun_desc
                    )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.data_limbah.timbulan_limbah_isolasi_terkendali__isoter_
                        .jumlah_limbah_infeksius, "dataPenyusun_desc", $event.target.value)}}}),_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v("kg/hari")])])],1)],1)],1),_c('v-card',{staticClass:"bordered_card rounded-max",attrs:{"outlined":""}},[_c('div',{staticClass:"mb-3"},[_c('b',[_vm._v("Timbulan Limbah Vaksinasi Covid-19")])]),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Jumlah limbah vaksinasi ")])])]),_c('v-col',{attrs:{"cols":"7"}},[_c('v-card',{staticClass:"d-flex align-center pl-2 px-5",staticStyle:{"width":"max-content"},attrs:{"outlined":""}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                      _vm.data_limbah.timbulan_limbah_vaksinasi_covid_19
                        .jumlah_limbah_vaksinasi.dataPenyusun_desc
                    ),expression:"\n                      data_limbah.timbulan_limbah_vaksinasi_covid_19\n                        .jumlah_limbah_vaksinasi.dataPenyusun_desc\n                    "}],staticClass:"pa-2 mr-1 custom_input",attrs:{"type":"number","disabled":""},domProps:{"value":(
                      _vm.data_limbah.timbulan_limbah_vaksinasi_covid_19
                        .jumlah_limbah_vaksinasi.dataPenyusun_desc
                    )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.data_limbah.timbulan_limbah_vaksinasi_covid_19
                        .jumlah_limbah_vaksinasi, "dataPenyusun_desc", $event.target.value)}}}),_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v("kg/hari")])])],1)],1)],1),_c('div',{staticClass:"mb-5 bg_header",attrs:{"flat":""}},[_c('div',{staticClass:"fill_header"},[_c('b',[_vm._v("3. Alur Pengelolaan Limbah Padat Covid-19")])])]),_c('v-card',{staticClass:"bordered_card rounded-max",attrs:{"outlined":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Apakah mau menggunakan data yang sama dengan data pengolahan limbah medis fasyankes? ")])])]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"disabled":"","row":"","dense":"","hide-details":""},model:{value:(
                    _vm.olah_limbah.pengolah_limbah_medis_fasyankes
                      .apakah_mau_menggunakan_data_yang_sama_dengan_data_pengolah_limbah_medis_fasyankes
                      .dataPenyusun_desc
                  ),callback:function ($$v) {_vm.$set(_vm.olah_limbah.pengolah_limbah_medis_fasyankes
                      .apakah_mau_menggunakan_data_yang_sama_dengan_data_pengolah_limbah_medis_fasyankes
                      , "dataPenyusun_desc", $$v)},expression:"\n                    olah_limbah.pengolah_limbah_medis_fasyankes\n                      .apakah_mau_menggunakan_data_yang_sama_dengan_data_pengolah_limbah_medis_fasyankes\n                      .dataPenyusun_desc\n                  "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ya","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak","value":"2"}})],1)],1)],1)],1),_c('v-card',{staticClass:"bordered_card rounded-max",attrs:{"outlined":""}},[_c('div',{staticClass:"mb-3"},[_c('b',[_vm._v("Ketersediaan Alat Pengolah Limbah Di Fasyankes")])]),_c('v-card',{staticClass:"pa-2 rounded-lg mb-4",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Apakah terdapat alat pengolah limbah insinerator ")])])]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"disabled":"","row":"","dense":"","hide-details":""},model:{value:(
                      _vm.olah_limbah.ketersediaan_alat_pengolah_limbah
                        .apakah_terdapat_alat_pengolah_limbah_insinerator
                        .dataPenyusun_desc
                    ),callback:function ($$v) {_vm.$set(_vm.olah_limbah.ketersediaan_alat_pengolah_limbah
                        .apakah_terdapat_alat_pengolah_limbah_insinerator
                        , "dataPenyusun_desc", $$v)},expression:"\n                      olah_limbah.ketersediaan_alat_pengolah_limbah\n                        .apakah_terdapat_alat_pengolah_limbah_insinerator\n                        .dataPenyusun_desc\n                    "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ya","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak","value":"2"}})],1)],1)],1)],1),_c('v-row',{staticClass:"mb-3"},[_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Kapasitas Operasional: ")])])]),_c('v-col',{attrs:{"cols":"7"}},[_c('v-card',{staticClass:"d-flex align-center pl-2 px-5",staticStyle:{"width":"max-content"},attrs:{"outlined":""}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                      _vm.olah_limbah.ketersediaan_alat_pengolah_limbah
                        .apakah_terdapat_alat_pengolah_limbah_insinerator
                        .additional_value
                    ),expression:"\n                      olah_limbah.ketersediaan_alat_pengolah_limbah\n                        .apakah_terdapat_alat_pengolah_limbah_insinerator\n                        .additional_value\n                    "}],staticClass:"pa-2 mr-1 custom_input",attrs:{"type":"number","disabled":""},domProps:{"value":(
                      _vm.olah_limbah.ketersediaan_alat_pengolah_limbah
                        .apakah_terdapat_alat_pengolah_limbah_insinerator
                        .additional_value
                    )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.olah_limbah.ketersediaan_alat_pengolah_limbah
                        .apakah_terdapat_alat_pengolah_limbah_insinerator
                        , "additional_value", $event.target.value)}}}),_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v("Kg/jam")])])],1)],1),_c('v-card',{staticClass:"pa-2 rounded-lg mb-4",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Apakah terdapat alat pengolah limbah "),_c('i',[_vm._v("autoclave/microwave")])])])]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"disabled":"","row":"","dense":"","hide-details":""},model:{value:(
                      _vm.olah_limbah.ketersediaan_alat_pengolah_limbah
                        .apakah_terdapat_alat_pengolah_limbah_autoclave_microwave
                        .dataPenyusun_desc
                    ),callback:function ($$v) {_vm.$set(_vm.olah_limbah.ketersediaan_alat_pengolah_limbah
                        .apakah_terdapat_alat_pengolah_limbah_autoclave_microwave
                        , "dataPenyusun_desc", $$v)},expression:"\n                      olah_limbah.ketersediaan_alat_pengolah_limbah\n                        .apakah_terdapat_alat_pengolah_limbah_autoclave_microwave\n                        .dataPenyusun_desc\n                    "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ya","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak","value":"2"}})],1)],1)],1)],1),_c('v-row',{staticClass:"mb-3"},[_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Kapasitas Operasional: ")])])]),_c('v-col',{attrs:{"cols":"7"}},[_c('v-card',{staticClass:"d-flex align-center pl-2 px-5",staticStyle:{"width":"max-content"},attrs:{"outlined":""}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                      _vm.olah_limbah.ketersediaan_alat_pengolah_limbah
                        .apakah_terdapat_alat_pengolah_limbah_autoclave_microwave
                        .additional_value
                    ),expression:"\n                      olah_limbah.ketersediaan_alat_pengolah_limbah\n                        .apakah_terdapat_alat_pengolah_limbah_autoclave_microwave\n                        .additional_value\n                    "}],staticClass:"pa-2 mr-1 custom_input",attrs:{"type":"number","disabled":""},domProps:{"value":(
                      _vm.olah_limbah.ketersediaan_alat_pengolah_limbah
                        .apakah_terdapat_alat_pengolah_limbah_autoclave_microwave
                        .additional_value
                    )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.olah_limbah.ketersediaan_alat_pengolah_limbah
                        .apakah_terdapat_alat_pengolah_limbah_autoclave_microwave
                        , "additional_value", $event.target.value)}}}),_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v("Kg/jam")])])],1)],1)],1),_c('v-card',{staticClass:"bordered_card rounded-max",attrs:{"outlined":""}},[_c('div',{staticClass:"mb-3"},[_c('b',[_vm._v("Spesifikasi pengolahan limbah")])]),_c('v-card',{staticClass:"pa-2 rounded-lg mb-4",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Diolah dengan insinerator ")])])]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"disabled":"","row":"","dense":"","hide-details":""},model:{value:(
                      _vm.olah_limbah.spesifikasi_pengolahan_limbah
                        .diolah_dengan_insinerator.dataPenyusun_desc
                    ),callback:function ($$v) {_vm.$set(_vm.olah_limbah.spesifikasi_pengolahan_limbah
                        .diolah_dengan_insinerator, "dataPenyusun_desc", $$v)},expression:"\n                      olah_limbah.spesifikasi_pengolahan_limbah\n                        .diolah_dengan_insinerator.dataPenyusun_desc\n                    "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ya","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak","value":"2"}})],1)],1)],1)],1),_c('div',{staticClass:"d-flex align-center mb-4"},[_c('v-card',{staticClass:"d-flex align-center pl-2",staticStyle:{"width":"max-content"},attrs:{"outlined":""}},[_c('p',{staticClass:"ma-0 small_txt color_txt mr-2"},[_vm._v("No")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                    _vm.olah_limbah.spesifikasi_pengolahan_limbah
                      .diolah_dengan_insinerator.additional_nomer
                  ),expression:"\n                    olah_limbah.spesifikasi_pengolahan_limbah\n                      .diolah_dengan_insinerator.additional_nomer\n                  "}],staticClass:"pa-2 custom_input",attrs:{"type":"number","disabled":""},domProps:{"value":(
                    _vm.olah_limbah.spesifikasi_pengolahan_limbah
                      .diolah_dengan_insinerator.additional_nomer
                  )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.olah_limbah.spesifikasi_pengolahan_limbah
                      .diolah_dengan_insinerator, "additional_nomer", $event.target.value)}}})]),_c('div',{staticClass:"mx-3",staticStyle:{"width":"20px"}},[_c('v-divider')],1),_c('v-card',{staticClass:"d-flex align-center pl-2",staticStyle:{"width":"max-content"},attrs:{"outlined":""}},[_c('div',{staticClass:"marlef"},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v("Tanggal Izin")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                    _vm.olah_limbah.spesifikasi_pengolahan_limbah
                    .diolah_dengan_insinerator.additional_date
                  ),expression:"\n                    olah_limbah.spesifikasi_pengolahan_limbah\n                    .diolah_dengan_insinerator.additional_date\n                  "}],staticClass:"pa-2 custom_date",attrs:{"type":"text","placeholder":"mm/dd/yyyy","readonly":""},domProps:{"value":(
                    _vm.olah_limbah.spesifikasi_pengolahan_limbah
                    .diolah_dengan_insinerator.additional_date
                  )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.olah_limbah.spesifikasi_pengolahan_limbah
                    .diolah_dengan_insinerator, "additional_date", $event.target.value)}}}),_c('v-icon',[_vm._v("mdi-calendar")])],1)],1),_c('v-card',{staticClass:"pa-2 rounded-lg mb-4",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Apakah terdapat alat pengolah limbah "),_c('i',[_vm._v("autoclave/microwave")])])])]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","disabled":"","dense":"","hide-details":""},model:{value:(
                      _vm.olah_limbah.spesifikasi_pengolahan_limbah
                        .apakah_terdapat_alat_pengolah_limbah_autoclave_microwave
                        .dataPenyusun_desc
                    ),callback:function ($$v) {_vm.$set(_vm.olah_limbah.spesifikasi_pengolahan_limbah
                        .apakah_terdapat_alat_pengolah_limbah_autoclave_microwave
                        , "dataPenyusun_desc", $$v)},expression:"\n                      olah_limbah.spesifikasi_pengolahan_limbah\n                        .apakah_terdapat_alat_pengolah_limbah_autoclave_microwave\n                        .dataPenyusun_desc\n                    "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ya","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak","value":"2"}})],1)],1)],1)],1),_c('div',{staticClass:"d-flex align-center mb-4"},[_c('v-card',{staticClass:"d-flex align-center pl-2",staticStyle:{"width":"max-content"},attrs:{"outlined":""}},[_c('p',{staticClass:"ma-0 small_txt color_txt mr-2"},[_vm._v("No")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                    _vm.olah_limbah.spesifikasi_pengolahan_limbah
                      .apakah_terdapat_alat_pengolah_limbah_autoclave_microwave
                      .additional_nomer
                  ),expression:"\n                    olah_limbah.spesifikasi_pengolahan_limbah\n                      .apakah_terdapat_alat_pengolah_limbah_autoclave_microwave\n                      .additional_nomer\n                  "}],staticClass:"pa-2 custom_input",attrs:{"type":"number","readonly":""},domProps:{"value":(
                    _vm.olah_limbah.spesifikasi_pengolahan_limbah
                      .apakah_terdapat_alat_pengolah_limbah_autoclave_microwave
                      .additional_nomer
                  )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.olah_limbah.spesifikasi_pengolahan_limbah
                      .apakah_terdapat_alat_pengolah_limbah_autoclave_microwave
                      , "additional_nomer", $event.target.value)}}})]),_c('div',{staticClass:"mx-3",staticStyle:{"width":"20px"}},[_c('v-divider')],1),_c('v-card',{staticClass:"d-flex align-center pl-2",staticStyle:{"width":"max-content"},attrs:{"outlined":""}},[_c('div',{staticClass:"marlef"},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v("Tanggal Izin")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                    _vm.olah_limbah.spesifikasi_pengolahan_limbah
                      .apakah_terdapat_alat_pengolah_limbah_autoclave_microwave
                      .additional_date
                  ),expression:"\n                    olah_limbah.spesifikasi_pengolahan_limbah\n                      .apakah_terdapat_alat_pengolah_limbah_autoclave_microwave\n                      .additional_date\n                  "}],staticClass:"pa-2 custom_date",attrs:{"type":"text","placeholder":"mm/dd/yyyy","readonly":""},domProps:{"value":(
                    _vm.olah_limbah.spesifikasi_pengolahan_limbah
                      .apakah_terdapat_alat_pengolah_limbah_autoclave_microwave
                      .additional_date
                  )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.olah_limbah.spesifikasi_pengolahan_limbah
                      .apakah_terdapat_alat_pengolah_limbah_autoclave_microwave
                      , "additional_date", $event.target.value)}}}),_c('v-icon',[_vm._v("mdi-calendar")])],1)],1),_c('v-card',{staticClass:"pa-2 rounded-lg mb-4",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Bekerja sama dengan pengolah limbah medis ")])])]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"disabled":"","row":"","dense":"","hide-details":""},model:{value:(
                      _vm.olah_limbah.spesifikasi_pengolahan_limbah
                        .bekerja_sama_dengan_pengolah_limbah_medis
                        .dataPenyusun_desc
                    ),callback:function ($$v) {_vm.$set(_vm.olah_limbah.spesifikasi_pengolahan_limbah
                        .bekerja_sama_dengan_pengolah_limbah_medis
                        , "dataPenyusun_desc", $$v)},expression:"\n                      olah_limbah.spesifikasi_pengolahan_limbah\n                        .bekerja_sama_dengan_pengolah_limbah_medis\n                        .dataPenyusun_desc\n                    "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ya","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak","value":"2"}})],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{staticClass:"d-flex align-center pl-2 mr-5",attrs:{"outlined":""}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                      _vm.olah_limbah.spesifikasi_pengolahan_limbah
                        .bekerja_sama_dengan_pengolah_limbah_medis
                        .additional_name_company
                    ),expression:"\n                      olah_limbah.spesifikasi_pengolahan_limbah\n                        .bekerja_sama_dengan_pengolah_limbah_medis\n                        .additional_name_company\n                    "}],staticClass:"pa-2 custom_long_input",attrs:{"type":"text","readonly":"","placeholder":"Nama Perusahaan pengelola limbah sesuai dengan PKS"},domProps:{"value":(
                      _vm.olah_limbah.spesifikasi_pengolahan_limbah
                        .bekerja_sama_dengan_pengolah_limbah_medis
                        .additional_name_company
                    )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.olah_limbah.spesifikasi_pengolahan_limbah
                        .bekerja_sama_dengan_pengolah_limbah_medis
                        , "additional_name_company", $event.target.value)}}})])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center mb-4"},[_c('v-card',{staticClass:"d-flex align-center pl-2",staticStyle:{"width":"max-content"},attrs:{"outlined":""}},[_c('p',{staticClass:"ma-0 small_txt color_txt mr-2"},[_vm._v("No")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                        _vm.olah_limbah.spesifikasi_pengolahan_limbah
                          .bekerja_sama_dengan_pengolah_limbah_medis
                          .additional_nomer
                      ),expression:"\n                        olah_limbah.spesifikasi_pengolahan_limbah\n                          .bekerja_sama_dengan_pengolah_limbah_medis\n                          .additional_nomer\n                      "}],staticClass:"pa-2 custom_mini",staticStyle:{"width":"70px"},attrs:{"readonly":"","type":"number"},domProps:{"value":(
                        _vm.olah_limbah.spesifikasi_pengolahan_limbah
                          .bekerja_sama_dengan_pengolah_limbah_medis
                          .additional_nomer
                      )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.olah_limbah.spesifikasi_pengolahan_limbah
                          .bekerja_sama_dengan_pengolah_limbah_medis
                          , "additional_nomer", $event.target.value)}}})]),_c('div',{staticClass:"mx-3",staticStyle:{"width":"10px"}},[_c('v-divider')],1)],1)])],1),_c('v-card',{staticClass:"pa-2 rounded-lg mb-4",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Diolah dengan metode lainnya ")])])]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","disabled":"","dense":"","hide-details":""},model:{value:(
                      _vm.olah_limbah.spesifikasi_pengolahan_limbah
                        .diolah_dengan_metode_lainnya.dataPenyusun_desc
                    ),callback:function ($$v) {_vm.$set(_vm.olah_limbah.spesifikasi_pengolahan_limbah
                        .diolah_dengan_metode_lainnya, "dataPenyusun_desc", $$v)},expression:"\n                      olah_limbah.spesifikasi_pengolahan_limbah\n                        .diolah_dengan_metode_lainnya.dataPenyusun_desc\n                    "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ya","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak","value":"2"}})],1)],1)],1)],1)],1),_c('v-card',{staticClass:"bordered_card rounded-max",attrs:{"outlined":""}},[_c('div',{staticClass:"mb-3"},[_c('b',[_vm._v("Pengolahan limbah metode lainnya")])]),_c('v-card',{staticClass:"pa-2 rounded-lg mb-4",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Disinfeksi untuk limbah infeksius padat ")])])]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"disabled":"","row":"","dense":"","hide-details":""},model:{value:(
                      _vm.olah_limbah.pengolahan_limbah_metode_lain
                        .disinfeksi_untuk_limbah_infeksius_padat
                        .dataPenyusun_desc
                    ),callback:function ($$v) {_vm.$set(_vm.olah_limbah.pengolahan_limbah_metode_lain
                        .disinfeksi_untuk_limbah_infeksius_padat
                        , "dataPenyusun_desc", $$v)},expression:"\n                      olah_limbah.pengolahan_limbah_metode_lain\n                        .disinfeksi_untuk_limbah_infeksius_padat\n                        .dataPenyusun_desc\n                    "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ya","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak","value":"2"}})],1)],1)],1)],1),_c('v-card',{staticClass:"pa-2 rounded-lg mb-4",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Inertifikasi / solidifikasi untuk limbah farmasi dan kimia padat ")])])]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"disabled":"","row":"","dense":"","hide-details":""},model:{value:(
                      _vm.olah_limbah.pengolahan_limbah_metode_lain
                        .inertifikasi_solidifikasi_untuk_limbah_farmasi_dan_kimia_padat
                        .dataPenyusun_desc
                    ),callback:function ($$v) {_vm.$set(_vm.olah_limbah.pengolahan_limbah_metode_lain
                        .inertifikasi_solidifikasi_untuk_limbah_farmasi_dan_kimia_padat
                        , "dataPenyusun_desc", $$v)},expression:"\n                      olah_limbah.pengolahan_limbah_metode_lain\n                        .inertifikasi_solidifikasi_untuk_limbah_farmasi_dan_kimia_padat\n                        .dataPenyusun_desc\n                    "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ya","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak","value":"2"}})],1)],1)],1)],1),_c('v-card',{staticClass:"pa-2 rounded-lg mb-4",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Enkapsulasi untuk limbah tajam ")])])]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"disabled":"","row":"","dense":"","hide-details":""},model:{value:(
                      _vm.olah_limbah.pengolahan_limbah_metode_lain
                        .enkapsulasi_untuk_limbah_tajam.dataPenyusun_desc
                    ),callback:function ($$v) {_vm.$set(_vm.olah_limbah.pengolahan_limbah_metode_lain
                        .enkapsulasi_untuk_limbah_tajam, "dataPenyusun_desc", $$v)},expression:"\n                      olah_limbah.pengolahan_limbah_metode_lain\n                        .enkapsulasi_untuk_limbah_tajam.dataPenyusun_desc\n                    "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ya","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak","value":"2"}})],1)],1)],1)],1),_c('v-card',{staticClass:"pa-2 rounded-lg mb-4",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Penguburan limbah patologi ")])])]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"disabled":"","row":"","dense":"","hide-details":""},model:{value:(
                      _vm.olah_limbah.pengolahan_limbah_metode_lain
                        .penguburan_limbah_patologi.dataPenyusun_desc
                    ),callback:function ($$v) {_vm.$set(_vm.olah_limbah.pengolahan_limbah_metode_lain
                        .penguburan_limbah_patologi, "dataPenyusun_desc", $$v)},expression:"\n                      olah_limbah.pengolahan_limbah_metode_lain\n                        .penguburan_limbah_patologi.dataPenyusun_desc\n                    "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ya","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak","value":"2"}})],1)],1)],1)],1)],1),_c('div',{staticClass:"mb-5 bg_header",attrs:{"flat":""}},[_c('div',{staticClass:"fill_header"},[_c('b',[_vm._v("4. Pengelolaan Limbah Cair Covid-19")])])]),_c('v-card',{staticClass:"bordered_card rounded-max",attrs:{"outlined":""}},[_c('div',{staticClass:"mb-3"},[_c('b',[_vm._v("Metode pengolahan limbah cair")])]),_c('v-card',{staticClass:"pa-2 rounded-lg mb-4",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Apakah menggunakan metode Klorinasi ")])])]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","disabled":"","dense":"","hide-details":""},model:{value:(
                      _vm.olah_limbah_cair.metode_pengolahan_limbah_cair
                        .apakah_menggunakan_metode_klorinasi.dataPenyusun_desc
                    ),callback:function ($$v) {_vm.$set(_vm.olah_limbah_cair.metode_pengolahan_limbah_cair
                        .apakah_menggunakan_metode_klorinasi, "dataPenyusun_desc", $$v)},expression:"\n                      olah_limbah_cair.metode_pengolahan_limbah_cair\n                        .apakah_menggunakan_metode_klorinasi.dataPenyusun_desc\n                    "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ya","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak","value":"2"}})],1)],1)],1)],1),_c('v-card',{staticClass:"pa-2 rounded-lg mb-4",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Apakah menggunakan metode Ultraviolet ")])])]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"disabled":"","row":"","dense":"","hide-details":""},model:{value:(
                      _vm.olah_limbah_cair.metode_pengolahan_limbah_cair
                        .apakah_menggunakan_metode_ultraviolet
                        .dataPenyusun_desc
                    ),callback:function ($$v) {_vm.$set(_vm.olah_limbah_cair.metode_pengolahan_limbah_cair
                        .apakah_menggunakan_metode_ultraviolet
                        , "dataPenyusun_desc", $$v)},expression:"\n                      olah_limbah_cair.metode_pengolahan_limbah_cair\n                        .apakah_menggunakan_metode_ultraviolet\n                        .dataPenyusun_desc\n                    "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ya","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak","value":"2"}})],1)],1)],1)],1),_c('v-card',{staticClass:"pa-2 rounded-lg mb-4",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Apakah menggunakan metode Ozon ")])])]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"disabled":"","row":"","dense":"","hide-details":""},model:{value:(
                      _vm.olah_limbah_cair.metode_pengolahan_limbah_cair
                        .apakah_menggunakan_metode_ozon.dataPenyusun_desc
                    ),callback:function ($$v) {_vm.$set(_vm.olah_limbah_cair.metode_pengolahan_limbah_cair
                        .apakah_menggunakan_metode_ozon, "dataPenyusun_desc", $$v)},expression:"\n                      olah_limbah_cair.metode_pengolahan_limbah_cair\n                        .apakah_menggunakan_metode_ozon.dataPenyusun_desc\n                    "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ya","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak","value":"2"}})],1)],1)],1)],1)],1)],1)])],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }