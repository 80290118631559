<template>
  <div>
    <div v-if="sumber_limbah && data_limbah && olah_limbah && olah_limbah_cair">
      <!-- isian muali -->
      <div>
        <vue-html2pdf
          :show-layout="false"
          :enable-download="true"
          :preview-modal="true"
          :paginate-elements-by-height="1400"
          filename="formulir-covid19"
          :pdf-quality="2"
          :manual-pagination="false"
          pdf-format="a4"
          pdf-orientation="portrait"
          pdf-content-width="100%"
          ref="html2Pdf"
        >
          <div slot="pdf-content" style="padding: 10px">
            <!-- TAB 1 -->
            <profile-detil-data :profile="profile" />
              <p class="small_txt color_txt">
                {{ $date(created).format("MMMM YYYY") }}
              </p>
            <div flat class="mb-5 bg_header">
              <div class="fill_header">
                <b>1. Sumber Limbah Padat Covid-19</b>
              </div>
            </div>
            <v-card outlined class="bordered_card rounded-max">
              <div class="mb-3">
                <b>Jumlah Pasien Covid-19</b>
              </div>
              <v-row>
                <v-col cols="4">
                  <div class="d-flex align-center" style="height: 100%">
                    <p class="ma-0 small_txt color_txt">
                      Rata-rata jumlah pasien covid-19 di Fasyankes
                    </p>
                  </div>
                </v-col>
                <v-col cols="7">
                  <v-card
                    class="d-flex align-center pl-2 px-5"
                    outlined
                    style="width: max-content"
                  >
                    <input
                      type="number"
                      v-model="
                        sumber_limbah.jumlah_pasien_covid_19
                          .rata_rata_jumlah_pasien_covid_19_di_fasyankes
                          .dataPenyusun_desc
                      "
                      disabled
                      class="pa-2 mr-1 custom_input"
                    />
                    <p class="ma-0 small_txt color_txt">Orang/hari</p>
                  </v-card>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <div class="d-flex align-center" style="height: 100%">
                    <p class="ma-0 small_txt color_txt">
                      Rata-rata jumlah pasien covid-19 di Isolasi Terkendali
                      (Isoter)
                    </p>
                  </div>
                </v-col>
                <v-col cols="7">
                  <v-card
                    class="d-flex align-center pl-2 px-5"
                    outlined
                    style="width: max-content"
                  >
                    <input
                      type="number"
                      v-model="
                        sumber_limbah.jumlah_pasien_covid_19
                          .rata_rata_jumlah_pasien_covid_19_di_isolasi_terkendali__isoter_
                          .dataPenyusun_desc
                      "
                      disabled
                      class="pa-2 mr-1 custom_input"
                    />
                    <p class="ma-0 small_txt color_txt">Orang/hari</p>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
            <!-- TAB 2 -->
            <div flat class="mb-5 bg_header">
              <div class="fill_header">
                <b>2. Data Limbah Padat Covid-19</b>
              </div>
            </div>
            <v-card outlined class="bordered_card rounded-max">
              <div class="mb-3">
                <b>Timbulan limbah domestik covid-19</b>
              </div>
              <v-row>
                <v-col cols="4">
                  <div class="d-flex align-center" style="height: 100%">
                    <p class="ma-0 small_txt color_txt">
                      Jumlah limbah domestik
                    </p>
                  </div>
                </v-col>
                <v-col cols="7">
                  <v-card
                    class="d-flex align-center pl-2 px-5"
                    outlined
                    style="width: max-content"
                  >
                    <input
                      type="number"
                      v-model="
                        data_limbah.timbulan_limbah_domestik_covid_19
                          .jumlah_limbah_domestik.dataPenyusun_desc
                      "
                      disabled
                      class="pa-2 mr-1 custom_input"
                    />
                    <p class="ma-0 small_txt color_txt">kg/hari</p>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
            <v-card outlined class="bordered_card rounded-max">
              <div class="mb-3">
                <b>Timbulan Limbah medis Infeksius covid-19</b>
              </div>
              <v-row>
                <v-col cols="4">
                  <div class="d-flex align-center" style="height: 100%">
                    <p class="ma-0 small_txt color_txt">
                      Jumlah limbah Infeksius
                    </p>
                  </div>
                </v-col>
                <v-col cols="7">
                  <v-card
                    class="d-flex align-center pl-2 px-5"
                    outlined
                    style="width: max-content"
                  >
                    <input
                      v-model="
                        data_limbah.timbulan_limbah_medis__infeksius_covid_19
                          .jumlah_limbah_infeksius.dataPenyusun_desc
                      "
                      disabled
                      type="number"
                      class="pa-2 mr-1 custom_input"
                    />
                    <p class="ma-0 small_txt color_txt">kg/hari</p>
                  </v-card>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <div class="d-flex align-center" style="height: 100%">
                    <p class="ma-0 small_txt color_txt">Jumlah limbah Tajam</p>
                  </div>
                </v-col>
                <v-col cols="7">
                  <v-card
                    class="d-flex align-center pl-2 px-5"
                    outlined
                    style="width: max-content"
                  >
                    <input
                      type="number"
                      v-model="
                        data_limbah.timbulan_limbah_medis__infeksius_covid_19
                          .jumlah_limbah_tajam.dataPenyusun_desc
                      "
                      disabled
                      class="pa-2 mr-1 custom_input"
                    />
                    <p class="ma-0 small_txt color_txt">kg/hari</p>
                  </v-card>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <div class="d-flex align-center" style="height: 100%">
                    <p class="ma-0 small_txt color_txt">
                      Jumlah limbah Farmasi
                    </p>
                  </div>
                </v-col>
                <v-col cols="7">
                  <v-card
                    class="d-flex align-center pl-2 px-5"
                    outlined
                    style="width: max-content"
                  >
                    <input
                      type="number"
                      v-model="
                        data_limbah.timbulan_limbah_medis__infeksius_covid_19
                          .jumlah_limbah_farmasi.dataPenyusun_desc
                      "
                      disabled
                      class="pa-2 mr-1 custom_input"
                    />
                    <p class="ma-0 small_txt color_txt">kg/hari</p>
                  </v-card>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <div class="d-flex align-center" style="height: 100%">
                    <p class="ma-0 small_txt color_txt">
                      Jumlah limbah Patologi
                    </p>
                  </div>
                </v-col>
                <v-col cols="7">
                  <v-card
                    class="d-flex align-center pl-2 px-5"
                    outlined
                    style="width: max-content"
                  >
                    <input
                      type="number"
                      v-model="
                        data_limbah.timbulan_limbah_medis__infeksius_covid_19
                          .jumlah_limbah_patologi.dataPenyusun_desc
                      "
                      disabled
                      class="pa-2 mr-1 custom_input"
                    />
                    <p class="ma-0 small_txt color_txt">kg/hari</p>
                  </v-card>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <div class="d-flex align-center" style="height: 100%">
                    <p class="ma-0 small_txt color_txt">Jumlah limbah Kimia</p>
                  </div>
                </v-col>
                <v-col cols="7">
                  <v-card
                    class="d-flex align-center pl-2 px-5"
                    outlined
                    style="width: max-content"
                  >
                    <input
                      type="number"
                      v-model="
                        data_limbah.timbulan_limbah_medis__infeksius_covid_19
                          .jumlah_limbah_kimia.dataPenyusun_desc
                      "
                      disabled
                      class="pa-2 mr-1 custom_input"
                    />
                    <p class="ma-0 small_txt color_txt">kg/hari</p>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
            <v-card outlined class="bordered_card rounded-max">
              <div class="mb-3">
                <b>Timbulan Limbah Isolasi Terpusat (Isoter)</b>
              </div>
              <v-row>
                <v-col cols="4">
                  <div class="d-flex align-center" style="height: 100%">
                    <p class="ma-0 small_txt color_txt">
                      Jumlah limbah infeksius
                    </p>
                  </div>
                </v-col>
                <v-col cols="7">
                  <v-card
                    class="d-flex align-center pl-2 px-5"
                    outlined
                    style="width: max-content"
                  >
                    <input
                      type="number"
                      v-model="
                        data_limbah.timbulan_limbah_isolasi_terkendali__isoter_
                          .jumlah_limbah_infeksius.dataPenyusun_desc
                      "
                      disabled
                      class="pa-2 mr-1 custom_input"
                    />
                    <p class="ma-0 small_txt color_txt">kg/hari</p>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
            <v-card outlined class="bordered_card rounded-max">
              <div class="mb-3">
                <b>Timbulan Limbah Vaksinasi Covid-19</b>
              </div>
              <v-row>
                <v-col cols="4">
                  <div class="d-flex align-center" style="height: 100%">
                    <p class="ma-0 small_txt color_txt">
                      Jumlah limbah vaksinasi
                    </p>
                  </div>
                </v-col>
                <v-col cols="7">
                  <v-card
                    class="d-flex align-center pl-2 px-5"
                    outlined
                    style="width: max-content"
                  >
                    <input
                      type="number"
                      v-model="
                        data_limbah.timbulan_limbah_vaksinasi_covid_19
                          .jumlah_limbah_vaksinasi.dataPenyusun_desc
                      "
                      disabled
                      class="pa-2 mr-1 custom_input"
                    />
                    <p class="ma-0 small_txt color_txt">kg/hari</p>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
            <!-- TAB 3 -->
            <div flat class="mb-5 bg_header">
              <div class="fill_header">
                <b>3. Alur Pengelolaan Limbah Padat Covid-19</b>
              </div>
            </div>
            <v-card outlined class="bordered_card rounded-max">
              <v-row>
                <v-col cols="9">
                  <div class="d-flex align-center" style="height: 100%">
                    <p class="ma-0 small_txt color_txt">
                      Apakah mau menggunakan data yang sama dengan data
                      pengolahan limbah medis fasyankes?
                    </p>
                  </div>
                </v-col>
                <v-col cols="3">
                  <v-radio-group
                    disabled
                    v-model="
                      olah_limbah.pengolah_limbah_medis_fasyankes
                        .apakah_mau_menggunakan_data_yang_sama_dengan_data_pengolah_limbah_medis_fasyankes
                        .dataPenyusun_desc
                    "
                    row
                    dense
                    hide-details
                    class="ma-0"
                  >
                    <v-radio color="#00ccb6" label="Ya" value="1"></v-radio>
                    <v-radio color="#00ccb6" label="Tidak" value="2"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-card>
            <v-card outlined class="bordered_card rounded-max">
              <div class="mb-3">
                <b>Ketersediaan Alat Pengolah Limbah Di Fasyankes</b>
              </div>
              <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                <v-row>
                  <v-col cols="9">
                    <div class="d-flex align-center" style="height: 100%">
                      <p class="ma-0 small_txt color_txt">
                        Apakah terdapat alat pengolah limbah insinerator
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="3">
                    <v-radio-group
                      disabled
                      v-model="
                        olah_limbah.ketersediaan_alat_pengolah_limbah
                          .apakah_terdapat_alat_pengolah_limbah_insinerator
                          .dataPenyusun_desc
                      "
                      row
                      dense
                      hide-details
                      class="ma-0"
                    >
                      <v-radio color="#00ccb6" label="Ya" value="1"></v-radio>
                      <v-radio
                        color="#00ccb6"
                        label="Tidak"
                        value="2"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
              <v-row class="mb-3">
                <v-col cols="4">
                  <div class="d-flex align-center" style="height: 100%">
                    <p class="ma-0 small_txt color_txt">
                      Kapasitas Operasional:
                    </p>
                  </div>
                </v-col>
                <v-col cols="7">
                  <v-card
                    class="d-flex align-center pl-2 px-5"
                    outlined
                    style="width: max-content"
                  >
                    <input
                      type="number"
                      v-model="
                        olah_limbah.ketersediaan_alat_pengolah_limbah
                          .apakah_terdapat_alat_pengolah_limbah_insinerator
                          .additional_value
                      "
                      disabled
                      class="pa-2 mr-1 custom_input"
                    />
                    <p class="ma-0 small_txt color_txt">Kg/jam</p>
                  </v-card>
                </v-col>
              </v-row>
              <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                <v-row>
                  <v-col cols="9">
                    <div class="d-flex align-center" style="height: 100%">
                      <p class="ma-0 small_txt color_txt">
                        Apakah terdapat alat pengolah limbah
                        <i>autoclave/microwave</i>
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="3">
                    <v-radio-group
                      disabled
                      v-model="
                        olah_limbah.ketersediaan_alat_pengolah_limbah
                          .apakah_terdapat_alat_pengolah_limbah_autoclave_microwave
                          .dataPenyusun_desc
                      "
                      row
                      dense
                      hide-details
                      class="ma-0"
                    >
                      <v-radio color="#00ccb6" label="Ya" value="1"></v-radio>
                      <v-radio
                        color="#00ccb6"
                        label="Tidak"
                        value="2"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
              <v-row class="mb-3">
                <v-col cols="4">
                  <div class="d-flex align-center" style="height: 100%">
                    <p class="ma-0 small_txt color_txt">
                      Kapasitas Operasional:
                    </p>
                  </div>
                </v-col>
                <v-col cols="7">
                  <v-card
                    class="d-flex align-center pl-2 px-5"
                    outlined
                    style="width: max-content"
                  >
                    <input
                      type="number"
                      v-model="
                        olah_limbah.ketersediaan_alat_pengolah_limbah
                          .apakah_terdapat_alat_pengolah_limbah_autoclave_microwave
                          .additional_value
                      "
                      disabled
                      class="pa-2 mr-1 custom_input"
                    />
                    <p class="ma-0 small_txt color_txt">Kg/jam</p>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
            <!-- -------------------------------------------------------SPESIFIKASI LIMBAH ----------------------------------------------------------->

            <v-card outlined class="bordered_card rounded-max">
              <div class="mb-3">
                <b>Spesifikasi pengolahan limbah</b>
              </div>
              <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                <v-row>
                  <v-col cols="9">
                    <div class="d-flex align-center" style="height: 100%">
                      <p class="ma-0 small_txt color_txt">
                        Diolah dengan insinerator
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="3">
                    <v-radio-group
                      disabled
                      v-model="
                        olah_limbah.spesifikasi_pengolahan_limbah
                          .diolah_dengan_insinerator.dataPenyusun_desc
                      "
                      row
                      dense
                      hide-details
                      class="ma-0"
                    >
                      <v-radio color="#00ccb6" label="Ya" value="1"></v-radio>
                      <v-radio
                        color="#00ccb6"
                        label="Tidak"
                        value="2"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
              <div class="d-flex align-center mb-4">
                <v-card
                  class="d-flex align-center pl-2"
                  outlined
                  style="width: max-content"
                >
                  <p class="ma-0 small_txt color_txt mr-2">No</p>
                  <input
                    type="number"
                    v-model="
                      olah_limbah.spesifikasi_pengolahan_limbah
                        .diolah_dengan_insinerator.additional_nomer
                    "
                    disabled
                    class="pa-2 custom_input"
                  />
                </v-card>
                <div class="mx-3" style="width: 20px">
                  <v-divider></v-divider>
                </div>
                <v-card
                  class="d-flex align-center pl-2"
                  outlined
                  style="width: max-content"
                >
                  <div class="marlef">
                    <p class="ma-0 small_txt color_txt">Tanggal Izin</p>
                  </div>

                  <input
                    type="text"
                    placeholder="mm/dd/yyyy"
                    readonly
                    v-model="
                      olah_limbah.spesifikasi_pengolahan_limbah
                      .diolah_dengan_insinerator.additional_date
                    "
                    class="pa-2 custom_date"
                  />

                  <v-icon>mdi-calendar</v-icon>
                </v-card>
              </div>
              <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                <v-row>
                  <v-col cols="9">
                    <div class="d-flex align-center" style="height: 100%">
                      <p class="ma-0 small_txt color_txt">
                        Apakah terdapat alat pengolah limbah
                        <i>autoclave/microwave</i>
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="3">
                    <v-radio-group
                      row
                      disabled
                      v-model="
                        olah_limbah.spesifikasi_pengolahan_limbah
                          .apakah_terdapat_alat_pengolah_limbah_autoclave_microwave
                          .dataPenyusun_desc
                      "
                      dense
                      hide-details
                      class="ma-0"
                    >
                      <v-radio color="#00ccb6" label="Ya" value="1"></v-radio>
                      <v-radio
                        color="#00ccb6"
                        label="Tidak"
                        value="2"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
              <div class="d-flex align-center mb-4">
                <v-card
                  class="d-flex align-center pl-2"
                  outlined
                  style="width: max-content"
                >
                  <p class="ma-0 small_txt color_txt mr-2">No</p>
                  <input
                    type="number"
                    v-model="
                      olah_limbah.spesifikasi_pengolahan_limbah
                        .apakah_terdapat_alat_pengolah_limbah_autoclave_microwave
                        .additional_nomer
                    "
                    readonly
                    class="pa-2 custom_input"
                  />
                </v-card>
                <div class="mx-3" style="width: 20px">
                  <v-divider></v-divider>
                </div>
                <v-card
                  class="d-flex align-center pl-2"
                  outlined
                  style="width: max-content"
                >
                  <div class="marlef">
                    <p class="ma-0 small_txt color_txt">Tanggal Izin</p>
                  </div>
                  <input
                    type="text"
                    placeholder="mm/dd/yyyy"
                    readonly
                    v-model="
                      olah_limbah.spesifikasi_pengolahan_limbah
                        .apakah_terdapat_alat_pengolah_limbah_autoclave_microwave
                        .additional_date
                    "
                    class="pa-2 custom_date"
                  />

                  <v-icon>mdi-calendar</v-icon>
                </v-card>
              </div>
              <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                <v-row>
                  <v-col cols="9">
                    <div class="d-flex align-center" style="height: 100%">
                      <p class="ma-0 small_txt color_txt">
                        Bekerja sama dengan pengolah limbah medis
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="3">
                    <v-radio-group
                      disabled
                      v-model="
                        olah_limbah.spesifikasi_pengolahan_limbah
                          .bekerja_sama_dengan_pengolah_limbah_medis
                          .dataPenyusun_desc
                      "
                      row
                      dense
                      hide-details
                      class="ma-0"
                    >
                      <v-radio color="#00ccb6" label="Ya" value="1"></v-radio>
                      <v-radio
                        color="#00ccb6"
                        label="Tidak"
                        value="2"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
              <v-row>
                <v-col cols="6">
                  <v-card class="d-flex align-center pl-2 mr-5" outlined>
                    <input
                      type="text"
                      readonly
                      v-model="
                        olah_limbah.spesifikasi_pengolahan_limbah
                          .bekerja_sama_dengan_pengolah_limbah_medis
                          .additional_name_company
                      "
                      placeholder="Nama Perusahaan pengelola limbah sesuai dengan PKS"
                      class="pa-2 custom_long_input"
                    />
                  </v-card>
                </v-col>
                <v-col cols="6">
                  <div class="d-flex align-center mb-4">
                    <v-card
                      class="d-flex align-center pl-2"
                      outlined
                      style="width: max-content"
                    >
                      <p class="ma-0 small_txt color_txt mr-2">No</p>
                      <input
                        readonly
                        v-model="
                          olah_limbah.spesifikasi_pengolahan_limbah
                            .bekerja_sama_dengan_pengolah_limbah_medis
                            .additional_nomer
                        "
                        type="number"
                        class="pa-2 custom_mini"
                        style="width: 70px"
                      />
                    </v-card>
                    <div class="mx-3" style="width: 10px">
                      <v-divider></v-divider>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                <v-row>
                  <v-col cols="9">
                    <div class="d-flex align-center" style="height: 100%">
                      <p class="ma-0 small_txt color_txt">
                        Diolah dengan metode lainnya
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="3">
                    <v-radio-group
                      row
                      disabled
                      v-model="
                        olah_limbah.spesifikasi_pengolahan_limbah
                          .diolah_dengan_metode_lainnya.dataPenyusun_desc
                      "
                      dense
                      hide-details
                      class="ma-0"
                    >
                      <v-radio color="#00ccb6" label="Ya" value="1"></v-radio>
                      <v-radio
                        color="#00ccb6"
                        label="Tidak"
                        value="2"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
            </v-card>

            <!-- ------------------------------------------------------------------------------------------------------------------------------- -->
            <v-card outlined class="bordered_card rounded-max">
              <div class="mb-3">
                <b>Pengolahan limbah metode lainnya</b>
              </div>
              <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                <v-row>
                  <v-col cols="9">
                    <div class="d-flex align-center" style="height: 100%">
                      <p class="ma-0 small_txt color_txt">
                        Disinfeksi untuk limbah infeksius padat
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="3">
                    <v-radio-group
                      disabled
                      v-model="
                        olah_limbah.pengolahan_limbah_metode_lain
                          .disinfeksi_untuk_limbah_infeksius_padat
                          .dataPenyusun_desc
                      "
                      row
                      dense
                      hide-details
                      class="ma-0"
                    >
                      <v-radio color="#00ccb6" label="Ya" value="1"></v-radio>
                      <v-radio
                        color="#00ccb6"
                        label="Tidak"
                        value="2"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
              <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                <v-row>
                  <v-col cols="9">
                    <div class="d-flex align-center" style="height: 100%">
                      <p class="ma-0 small_txt color_txt">
                        Inertifikasi / solidifikasi untuk limbah farmasi dan
                        kimia padat
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="3">
                    <v-radio-group
                      disabled
                      v-model="
                        olah_limbah.pengolahan_limbah_metode_lain
                          .inertifikasi_solidifikasi_untuk_limbah_farmasi_dan_kimia_padat
                          .dataPenyusun_desc
                      "
                      row
                      dense
                      hide-details
                      class="ma-0"
                    >
                      <v-radio color="#00ccb6" label="Ya" value="1"></v-radio>
                      <v-radio
                        color="#00ccb6"
                        label="Tidak"
                        value="2"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
              <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                <v-row>
                  <v-col cols="9">
                    <div class="d-flex align-center" style="height: 100%">
                      <p class="ma-0 small_txt color_txt">
                        Enkapsulasi untuk limbah tajam
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="3">
                    <v-radio-group
                      disabled
                      v-model="
                        olah_limbah.pengolahan_limbah_metode_lain
                          .enkapsulasi_untuk_limbah_tajam.dataPenyusun_desc
                      "
                      row
                      dense
                      hide-details
                      class="ma-0"
                    >
                      <v-radio color="#00ccb6" label="Ya" value="1"></v-radio>
                      <v-radio
                        color="#00ccb6"
                        label="Tidak"
                        value="2"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
              <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                <v-row>
                  <v-col cols="9">
                    <div class="d-flex align-center" style="height: 100%">
                      <p class="ma-0 small_txt color_txt">
                        Penguburan limbah patologi
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="3">
                    <v-radio-group
                      disabled
                      v-model="
                        olah_limbah.pengolahan_limbah_metode_lain
                          .penguburan_limbah_patologi.dataPenyusun_desc
                      "
                      row
                      dense
                      hide-details
                      class="ma-0"
                    >
                      <v-radio color="#00ccb6" label="Ya" value="1"></v-radio>
                      <v-radio
                        color="#00ccb6"
                        label="Tidak"
                        value="2"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
            </v-card>

            <!-- TAB 4 -->
            <div flat class="mb-5 bg_header">
              <div class="fill_header">
                <b>4. Pengelolaan Limbah Cair Covid-19</b>
              </div>
            </div>
            <v-card outlined class="bordered_card rounded-max">
              <div class="mb-3">
                <b>Metode pengolahan limbah cair</b>
              </div>
              <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                <v-row>
                  <v-col cols="9">
                    <div class="d-flex align-center" style="height: 100%">
                      <p class="ma-0 small_txt color_txt">
                        Apakah menggunakan metode Klorinasi
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="3">
                    <v-radio-group
                      row
                      v-model="
                        olah_limbah_cair.metode_pengolahan_limbah_cair
                          .apakah_menggunakan_metode_klorinasi.dataPenyusun_desc
                      "
                      disabled
                      dense
                      hide-details
                      class="ma-0"
                    >
                      <v-radio color="#00ccb6" label="Ya" value="1"></v-radio>
                      <v-radio
                        color="#00ccb6"
                        label="Tidak"
                        value="2"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
              <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                <v-row>
                  <v-col cols="9">
                    <div class="d-flex align-center" style="height: 100%">
                      <p class="ma-0 small_txt color_txt">
                        Apakah menggunakan metode Ultraviolet
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="3">
                    <v-radio-group
                      v-model="
                        olah_limbah_cair.metode_pengolahan_limbah_cair
                          .apakah_menggunakan_metode_ultraviolet
                          .dataPenyusun_desc
                      "
                      disabled
                      row
                      dense
                      hide-details
                      class="ma-0"
                    >
                      <v-radio color="#00ccb6" label="Ya" value="1"></v-radio>
                      <v-radio
                        color="#00ccb6"
                        label="Tidak"
                        value="2"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
              <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                <v-row>
                  <v-col cols="9">
                    <div class="d-flex align-center" style="height: 100%">
                      <p class="ma-0 small_txt color_txt">
                        Apakah menggunakan metode Ozon
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="3">
                    <v-radio-group
                      disabled
                      v-model="
                        olah_limbah_cair.metode_pengolahan_limbah_cair
                          .apakah_menggunakan_metode_ozon.dataPenyusun_desc
                      "
                      row
                      dense
                      hide-details
                      class="ma-0"
                    >
                      <v-radio color="#00ccb6" label="Ya" value="1"></v-radio>
                      <v-radio
                        color="#00ccb6"
                        label="Tidak"
                        value="2"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
            </v-card>
          </div>
        </vue-html2pdf>

        <!-- end -->
      </div>
    </div>
  </div>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";
import profileDetilData from "../../Data/Detail/profileDetilData.vue";
export default {
  components: { VueHtml2pdf, profileDetilData },
  props: ["isianData", "profile", "created"],
  computed: {
    sumber_limbah() {
      let data = null;
      if (this.isianData) {
        data = this.isianData.sumber_limbah_padat_covid_19;
      }
      return data;
    },
    data_limbah() {
      let data = null;
      if (this.isianData) {
        data = this.isianData.data_limbah_padat_covid_19;
      }
      return data;
    },
    olah_limbah() {
      let data = null;
      if (this.isianData) {
        data = this.isianData.pengolahan_limbah_padat_covid_19;
      }
      return data;
    },
    olah_limbah_cair() {
      let data = null;
      if (this.isianData) {
        data = this.isianData.pengelolaan_limbah_cair_covid_19;
      }
      return data;
    },
  },
  name: "printCovid",
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    generateForm() {
      this.$refs.html2Pdf.generatePdf();
    },
  },
};
</script>

<style scoped>
.custom_input {
  border-radius: 8px;
  width: 150px;
}
.custom_input::placeholder {
  font-size: small;
}
.custom_input:focus {
  outline: none;
}
.custom_mini {
  border-radius: 8px;
  width: 100px;
}
.custom_mini::placeholder {
  font-size: small;
}
.custom_mini:focus {
  outline: none;
}
.custom_long_input {
  border-radius: 8px;
  width: 100%;
}
.custom_long_input:focus {
  outline: none;
}
.custom_long_input::placeholder {
  font-size: small;
}

.custom_date {
  width: 120px;
}

.custom_date::placeholder {
  font-size: small;
}
.custom_date:focus {
  outline: none;
}
.temp_stepper_header {
  background: none !important;
  box-shadow: none !important;
}

.number_unactive {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #ececec;
  color: #5d5d5d;
  font-size: small;
  margin-right: 3px;
}
.number_active {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #fff;
  color: #00b4cc;
  font-size: small;
  margin-right: 3px;
}
.marlef {
  margin-right: 50px;
}
.side_toolbar {
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  z-index: 99 !important;
}
.item_toolbar {
  margin: 0 20px;
  padding: 15px;
  background: #fff;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: rgb(0 180 204 / 18%) 0px 5px 7px !important;
}
.fill_header {
  background: #00b4cc !important;
  width: 60%;
  padding: 10px;
  color: #fff;
}

.bordered_card {
  padding: 10px;
  margin-bottom: 10px;
}
.pa-2 {
  padding: 5px;
}
.px-5 {
  padding: 0 15px;
}
.py-3 {
  padding: 10px 0;
}
.mb-3 {
  margin-bottom: 12px;
}
.mb-4 {
  margin-bottom: 15px;
}
.mb-5 {
  margin-bottom: 20px;
}
.d-flex {
  display: flex;
}
.ma-0 {
  margin: 0;
}
.align-center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}
.bg_header {
  background: #cfeef2;
  border-radius: 5px;
}
.bg_subsoal {
  background: #f6f6f6;

}
</style>