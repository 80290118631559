<template>
  <div v-if="$isMobile()" class="navbar-formulir">
    <div class="isi-navbar">
      <div class="icon-first-formulir">
        <div>

        
        <img
          @click="getBack()"
          src="../../assets/img/icon-back-formulir.svg"
          alt=""
          style="cursor: pointer"
        />
      </div>
      <div>

      
        <img
          v-if="id"
          src="../../assets/img/icon-print-formulir.svg"
          alt=""
          style="cursor: pointer"
          @click="generatePdf()"
        />
      </div>
      </div>
      <div class="middle-formulir-nav">
        <h4>{{ title }}</h4>
        <div v-if="date">{{ $date(date).format("MMMM YYYY") }}</div>
      </div>
      <div v-if="profile">
        <div class="div-user">
          <!-- <div class="img-frame">
            <img src="../../assets/img/avatar.svg" alt="" />
          </div> -->
          <div class="name-user">
            <h4 style="color: white;">{{ profile.access_right }}</h4>
            <h6>{{ profile.full_name }}</h6>
          </div>
        </div>
      </div>
    </div>
    <div>
      <print-fasyankes :id="id" ref="prt1" v-if="type == 1" />
      <print-covid :id="id" ref="prt2" v-if="type == 2" />
      <print-kunci-kesling :id="id" ref="prt3" v-if="type == 3" />
      <print-sanitarian :id="id" ref="prt5" v-if="type == 5" />
    </div>
  </div>

  <div v-else class="navbar-formulir">
    <div class="isi-navbar">
      <div class="icon-first-formulir">
        <img
          @click="getBack()"
          src="../../assets/img/icon-back-formulir.svg"
          alt=""
          style="cursor: pointer"
        />
        <img
          v-if="id"
          src="../../assets/img/icon-print-formulir.svg"
          alt=""
          style="cursor: pointer"
          @click="generatePdf()"
        />
      </div>
      <div class="middle-formulir-nav">
        <h4>{{ title }}</h4>
        <p v-if="date">{{ $date(date).format("MMMM YYYY") }}</p>
      </div>
      <div v-if="profile">
        <div class="div-user">
          <div class="img-frame">
            <img src="../../assets/img/avatar.svg" alt="" />
          </div>
          <div class="name-user">
            <p>{{ profile.access_right }}</p>
            <h6>{{ profile.full_name }}</h6>
          </div>
        </div>
      </div>
    </div>
    <div>
      <print-fasyankes :id="id" ref="prt1" v-if="type == 1" />
      <print-covid :id="id" ref="prt2" v-if="type == 2" />
      <print-kunci-kesling :id="id" ref="prt3" v-if="type == 3" />
      <print-sanitarian :id="id" ref="prt5" v-if="type == 5" />
    </div>
  </div>

  
</template>

<script>
import Cookies from "js-cookie";
import { mapState } from "vuex";
import PrintCovid from "./Print/printCovid.vue";
import printFasyankes from "./Print/printFasyankes.vue";
import PrintKunciKesling from "./Print/printKunciKesling.vue";
import PrintSanitarian from "./Print/printSanitarian.vue";
export default {
  components: {
    printFasyankes,
    PrintCovid,
    PrintSanitarian,
    PrintKunciKesling,
  },
  name: "headerFormulir",
  props: ["title", "date", "id", "type"],
  computed: {
    ...mapState({
      role: (state) => state.role,
      profile: (state) => state.user.profile,
    }),
  },
  data() {
    return {
      isMobile: false,
    };
  },
  mounted() {
    this.getProfile();
  },
  methods: {
    generatePdf() {
      this.$refs[`prt${this.type}`].generateForm();
    },
    getBack() {
      this.$router.go(-1);
    },
    getProfile() {
      let id = Cookies.get("id");
      this.$store
        .dispatch("user/getProfile", id)
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.Swal("error", err.message);
        });
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 600px){
  .navbar-formulir {
    height: 250px;
    background: #00b4cc;
    width: 100%;
    padding: 20px 10px 30px 35px;
  }
  .isi-navbar {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: start;
  }

  .icon-first-formulir {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  

  .middle-formulir-nav {
    text-align: left;
    color: white;
}

.name-user {
    margin-left: 0px;
}
}
</style>